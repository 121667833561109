import React, { useState, useEffect, useRef } from "react";
import Headermobile from "./Headermobile";
import Footer from "./Footer";
import { Link, useParams } from "react-router-dom";
import { baseurl } from "../Component/Baseurl";
import ReactPlayer from "react-player";
import MM1 from "../images/MM1.webp";
import MM2 from "../images/MM2.webp";
import HH from "../images/Horoscope.webp";

import M1 from "../images/M1.webp";
import M2 from "../images/M2.webp";
import M3 from "../images/M3.webp";

function Dynamicpage() {
  const [adddata, setAdddata] = useState([]);

  const [rechargeData, setRechargeData] = useState(null);
  const { shortcode } = useParams();
  const tableRef = useRef(null);
  const [count, setCount] = useState(0);

  const fetchDataFromApi = async () => {
    try {
      const response = await fetch(`${baseurl}/rcpt-api/homepageold`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json", // Adjust the content type as needed
        },
        // You can send an empty object in the request body or omit it if not needed.
        body: JSON.stringify({ shortcode }),
      });

      if (response.ok) {
        const responseData = await response.text();
        // console.log(responseData);
        // window.location.href = 'https://www.rcpt.in'; // Replace with the URL you want to navigate to
      } else {
        console.error("API request failed");
      }
    } catch (error) {
      console.error("An error occurred", error);
    }
  };

  useEffect(() => {
    (window.adsbygoogle = window.adsbygoogle || []).push({});

    const apiEndpoint = `${baseurl}/rcpt-api/getrechargedetailsold?shortcode=${shortcode}`;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    };

    fetch(apiEndpoint, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data) && data.length > 0) {
          setRechargeData(data[0]);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    fetchDataFromApi();
  }, [shortcode]);
  console.log("rechargeData", JSON.stringify(rechargeData));
  useEffect(() => {
    fetch(`https://api.rcpt.in/rcpt-api/getaddslinkdetails`, {
      method: "POST", // or 'POST', depending on your API requirements
    })
      .then((response) => response.json())
      .then((data) => {
        setAdddata(data);

        // Update the state or handle the response as needed
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);
  const Gamezop = (id) => {
    // Make sure the ID is valid before calling increment
    if (id) {
      increment(id);
    } else {
      console.error("ID is not available");
    }
  };
  const increment = (id) => {
    fetch(`https://api.rcpt.in/rcpt-api/increasecount?id=${id}`, {
      method: "GET", // or 'POST', depending on your API requirements
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);

        // Update the state or handle the response as needed
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  return (
    <div className="receipt-container">
      <Headermobile />

      <div className="container">
        <div className="row my-2">
          <div className="col d-flex justify-content-center">
            <a
              href={adddata[0]?.addlink}
              onClick={() => Gamezop(adddata[0]?.id)}
            >
              <img src={adddata[0]?.imageurl} alt="mm1" className="img-fluid"  loading="lazy"/>
            </a>
          </div>
        </div>

        <div className="card respcard mt-2 mb-2">
          <h2>
            <b>
              <span className="receipttext">Recharge Receipt</span>
            </b>
          </h2>
          <div className="card-body recepitrow">
            {rechargeData ? (
              <table className="table" ref={tableRef}>
                <tbody>
                  <tr>
                    <th>Mobile No.</th>
                    <th>Transaction No.</th>
                    <th>Amount paid</th>
                  </tr>
                  <tr>
                    <td>{rechargeData["Source Number"]}</td>
                    <td>{rechargeData["Transaction ID"]}</td>
                    <td>{rechargeData["Recharge Value"]}</td>
                  </tr>
                </tbody>
              </table>
            ) : (
              <p>Loading...</p>
            )}
          </div>
          <div className="row rechdeatils mt-2">
            {rechargeData && (
              <div className="rechdeatilscol1">
                <table className="table">
                  <tbody>
                    <tr>
                      <td>Recharge Type :</td>
                      <td>{rechargeData["Recharge Type"]}</td>
                    </tr>
                    <tr>
                      <td>Validity of the Plan :</td>
                      <td class="notranslate">
                        {" "}
                        &nbsp; {rechargeData["Validity"]} &nbsp;{" "}
                      </td>
                    </tr>
                    <tr>
                      <td>Amount Paid :</td>
                      <td>{rechargeData["Recharge Value"]}</td>
                    </tr>
                    <tr>
                      <td>Transaction Date :</td>
                      <td class="notranslate"> {rechargeData["Date"]} </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>
          <div className="d-flex mt-3 mb-3 ml-1">
            <div className="specifwidth">
              <Link to="/Stvoffer">
                <button className="newbtn printbutton mt-2">STV Offers</button>
              </Link>
            </div>
            <div className="specifwidth">
              <Link to={`/Receiptdownload/${shortcode}`}>
                <button className="newbtn mt-2 dnbtn">
                  <i className="fa-solid fa-download" /> Receipt
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col d-flex justify-content-center">
          <a href={adddata[1]?.addlink} onClick={() => Gamezop(adddata[1]?.id)}>
            <img src={adddata[1]?.imageurl} alt="mm1" className="img-fluid" loading="lazy" />
          </a>
        </div>
      </div>

      <div className="row imagerow mt-3 mb-3  border-2 shadow-lg shadow-sm">
        <h1 className="text-danger pe-2 border-1">Trending </h1>
        <div className="col-lg col-md-6 col-sm-12 my-2 p-3 ">
          {/* <Link
            to="https://www.mobilemasala.com/film-gossip/John-Abraham-loses-cool-after-his-films-are-called-repetitive-at-Vedaa-trailer-launch-Can-I-call-out-idiots-Watch-i286295"
            className="d-flex  flex-column p-3  border"
          >
            <div className="m-2 border p-2">
              <img src={M1} alt="mm1" className="img-fluid" />
            </div>
            <div className="m-2">
              <h6>
                john abraham loses cool after his films are called repetitive at
                vedaa trailer launch: ‘can i call out idiots?’ watch{" "}
              </h6>
            </div>
          </Link> */}
          <div className="m-2 border p-2">
            <div className="player-wrapper">
              <ReactPlayer
                url="https://www.youtube.com/watch?v=jUWf9cBSFa8"
                playing={true}
                controls={true}
                className="react-player"
                config={{
                  youtube: {
                    playerVars: {
                      autoplay: 1,
                      mute: 1,
                    },
                  },
                }}
                width="100%"
                height="100%"
              />
            </div>

            <div className="m-2">
              <Link
                to="https://www.mobilemasala.com/numerology/Numerology-by-Srroth-Personality-7-v285229"
                className="d-flex  flex-column p-3  "
              >
                <h6>numerology by srroth – personality #7
</h6>
              </Link>
            </div>
          </div>
        </div>

        <div className="col-lg col-md-6 col-sm-12 my-2 p-3">
          <Link
            to="https://www.mobilemasala.com/film-gossip/Ganesh-Chaturthi-2024-Taimur-and-Jeh-pray-with-folded-hands-mom-Kareena-Kapoor-is-all-smiles-See-pic-i299699"
            className="d-flex  flex-column p-3  border"
          >
            <div className="m-2">
              <img src={M2} alt="mm1" className="img-fluid"  loading="lazy"/>
            </div>
            <div className="m-2">
              <h6>
ganesh chaturthi 2024: taimur and jeh pray with folded hands, mom kareena kapoor is all smiles. see pic
              </h6>
            </div>
          </Link>
        </div>

        <div className="col-lg col-md-6 col-sm-12 my-2 p-3">
          <Link
            to="https://www.mobilemasala.com/film-gossip/Rahas-cute-interaction-with-dadi-Neetu-Kapoor-caught-on-camera-in-adorable-airport-video-with-Ranbir-Kapoor-Alia-Bhatt-i299800"
            className="d-flex  flex-column p-3  border"
          >
            <div className="m-2">
              <img src={M3} alt="mm1" className="img-fluid" loading="lazy" />
            </div>
            <div className="m-2">
              <h6>
raha's cute interaction with dadi neetu kapoor caught on camera in adorable airport video with ranbir kapoor, alia bhatt
              </h6>
            </div>
          </Link>
        </div>
      </div>

      <div className="row m-2 d-none d-md-flex">
        <div className="col d-flex justify-content-center">
          <a href={adddata[2]?.addlink} onClick={() => Gamezop(adddata[2]?.id)}>
            <img src={adddata[2]?.imageurl} alt="mm1" className="img-fluid" loading="lazy" />
          </a>
        </div>
      </div>

      {/* mobile */}
      <div className="row m-2  d-md-none">
        <div className="col d-flex  justify-content-center">
          <a href={adddata[4]?.addlink} onClick={() => Gamezop(adddata[4]?.id)}>
            <img src={adddata[4]?.imageurl} alt="mm1" className="img-fluid" loading="lazy" />
          </a>
        </div>
      </div>
      {/* <div className="row imagerow mt-3 mb-3  border-2 shadow-lg shadow-sm d-flex justify-content-center">
        <h1 className="text-dark">Horoscope</h1>
        <div className="col-lg-6 col-md-6 col-sm-12 my-3 p-3">
          <Link
            to="https://www.mobilemasala.com/horoscope/Leo-s283"
            className="d-flex  flex-column p-3  border align-items-center justify-content-center"
          >
            <div className="m-2">
              <img src={HH} alt="mm1" className="img-fluid" />
            </div>
            <div className="m-2">
              <h1>Leo</h1>

              <h6>
                This month, Leos will experience a rejuvenation in personal and
                professional areas. Relationships flourish, career prospects
                improve, and financial stability is within reach. Health
                requires mindful attention.{" "}
              </h6>
            </div>
          </Link>
        </div>
      </div>

      <div className="row my-2 d-none d-md-flex ">
        <div className="col d-flex justify-content-center">
          <a href={adddata[3]?.addlink} onClick={() => Gamezop(adddata[3]?.id)}>
            <img src={adddata[3]?.imageurl} alt="mm1" className="img-fluid" />
          </a>
        </div>
      </div> */}
      {/* mobile */}
      {/* <div className="row my-2  d-md-none">
        <div className="col d-flex  justify-content-center">
          <a href={adddata[5]?.addlink} onClick={() => Gamezop(adddata[5]?.id)}>
            <img src={adddata[5]?.imageurl} alt="mm1" className="img-fluid" />
          </a>
        </div>
      </div> */}

      <Footer />
    </div>
  );
}

export default Dynamicpage;
