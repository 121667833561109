
import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Plans from "./Mobile/Plans";
import Receipt from "./Mobile/Receipt";
import Homemobile from "./Mobile/Homemobile";
import Home from "./Component/home/Home";
import Stvoffer from "./Mobile/Stvoffer";
import ReceiptComponent from "./Mobile/ReceiptComponent";
import Receiptdownload from "./Mobile/Receiptdownload";
import {  getToken } from 'firebase/messaging';
import { messaging } from './Notification/FirebaseToken';
import Dynamicpage from "./Mobile/Dynamicpage";
import NotificationForm from "./NotificationForm";
import { baseurl } from "./Component/Baseurl";
import Notificationpop from "./Notificationpop";
import Plan from "./Mobile/Plan";

import "./App.css";

function App() {
  const vapidPublicKey = 'BIWEp4o7qV5y9WjasPwfzp5l1GdRR-BoHr1a_72m2OnuhHXVm3ptr28J7d1IH51ftYw5psl4gJi92DADadp4YC4';

  useEffect(() => {
    const tokenSent = localStorage.getItem('tokenSent');
    if (!tokenSent) {
      requestPermission();
    }
  }, []);

  async function requestPermission() {
    try {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        const token = await getToken(messaging, { vapidKey: vapidPublicKey });
        if (token) {
          await sendTokenToApi(token);
          console.log("Token:", token);
          localStorage.setItem('tokenSent', 'true');
        } else {
          console.error("Failed to retrieve token.");
        }
      } else {
        console.error("Notification permission denied.");
      }
    } catch (error) {
      console.error("Error requesting notification permission:", error);
    }
  }

  async function sendTokenToApi(token) {
    try {
      const base64Token = btoa(JSON.stringify(token));
      const response = await fetch(`${baseurl}/rcpt-api/rcptinsertToken?token=${base64Token}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        console.log("Token sent to the API successfully.");
      } else {
        console.error("Failed to send token to the API.");
        console.log(base64Token);
      }
    } catch (error) {
      console.error("API request error:", error);
    }
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Homemobile />} />
        <Route path="/Plan" element={<Plan />} />
        <Route path="/Plans" element={<Plans />} />
        <Route path="/Notification" element={<NotificationForm />} />
   
        <Route path="/notificationpop" element={<Notificationpop />} />

        <Route path="/Home" element={<Home />} />
        <Route path="/Stvoffer" element={<Stvoffer />} />
        <Route path="/ReceiptComponent" element={<ReceiptComponent />} />
        <Route path="/Receiptdownload/:shortcode" element={<Receiptdownload />} />
        <Route path="/Receipt/:shortcode" element={<Receipt />} />
        <Route path="/:shortcode" element={<Dynamicpage />} />

      </Routes>
    </BrowserRouter>
  );
}

export default App;

