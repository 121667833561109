import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Headermobile from "./Headermobile";
import Footer from "./Footer";
import recharge from "../images/Mainrechargepic.jpeg";

import ReactPlayer from "react-player";
import recharge0 from "../images/RCPTjun24STV151.jpg";
import recharge1 from "../images/RCPTjun-24STV118.jpg";
import recharge2 from "../images/RCPTjun-24STV153.jpg";
import recharge3 from "../images/RCPTjun-24STV199.jpg";
import recharge4 from "../images/RCPTjun-24STV347.jpg";
import recharge5 from "../images/RCPTjun-24STV599.jpg";
import recharge6 from "../images/RCPTjun-24STV997.jpg";
import recharge7 from "../images/RCPTjun-24STV1999.jpg";
import recharge8 from "../images/RCPTjun-24STV2399.jpg";

function Stvoffer() {
  const [adddata, setAdddata] = useState([]);

  useEffect(() => {
    fetch(`https://api.rcpt.in/rcpt-api/getaddslinkdetails`, {
      method: "POST", // or 'POST', depending on your API requirements
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
        setAdddata(data);

        // Update the state or handle the response as needed
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  const Gamezop = (id) => {
    // Make sure the ID is valid before calling increment
    if (id) {
      increment(id);
    } else {
      console.error("ID is not available");
    }
  };
  const increment = (id) => {
    fetch(`https://api.rcpt.in/rcpt-api/increasecount?id=${id}`, {
      method: "GET", // or 'POST', depending on your API requirements
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);

        // Update the state or handle the response as needed
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  return (
    <>
      <Headermobile />
      <div className="">
        <div className="row imagerowbanner">
          <img src={recharge} alt="plan_all" />
        </div>

        <div className="row imagerowbanner">
          <img src={recharge1} alt="plan_118" />
        </div>

        <div className="row imagerowbanner">
          <a href={adddata[0]?.addlink} onClick={() => Gamezop(adddata[0]?.id)}>
            <img src={adddata[0]?.imageurl} alt="mm1" className="img-fluid" />
          </a>
        </div>
        <div className="row imagerowbanner mt-3">
          <img src={recharge0} alt="plan_151" />
        </div>
        <div className="row imagerowbanner mt-3">
          <img src={recharge2} alt="plan_153" />
        </div>

        <div className="row imagerowbanner ">
          <a href={adddata[1]?.addlink} onClick={() => Gamezop(adddata[1]?.id)}>
            <img src={adddata[1]?.imageurl} alt="mm1" className="img-fluid" />
          </a>
        </div>

        <div className="row imagerowbanner mt-3">
          <img src={recharge3} alt="plans_199" />
        </div>

        <div className="row imagerowbanner">
          <img src={recharge4} alt="plan_347" />
        </div>

        <div className="row imagerowbanner mt-3">
          <a href={adddata[2]?.addlink} onClick={() => Gamezop(adddata[2]?.id)}>
            <img src={adddata[2]?.imageurl} alt="mm1" className="img-fluid" />
          </a>
        </div>
        <div className="row imagerowbanner">
          <img src={recharge5} alt="plan_599" />
        </div>

        <div className="row imagerowbanner">
          <img src={recharge6} alt="plan_997" />
        </div>
        <div className="row imagerowbanner mt-3">
          <a href={adddata[3]?.addlink} onClick={() => Gamezop(adddata[3]?.id)}>
            <img src={adddata[3]?.imageurl} alt="mm1" className="img-fluid" />
          </a>
        </div>
        <div className="row imagerowbanner">
          <img src={recharge7} alt="plan_1999" />
        </div>
        <div className="row imagerowbanner">
          <img src={recharge8} alt="plan_2399" />
        </div>
        <div className="row imagerowbanner mt-3">
          <a href={adddata[1]?.addlink} onClick={() => Gamezop(adddata[1]?.id)}>
            <img src={adddata[1]?.imageurl} alt="mm1" className="img-fluid" />
          </a>
        </div>
        <div className="d-flex mt-3 mb-3 ml-1 container">
          <div className="specifwidth">
            <Link to="/">
              <button className="newbtn printbutton mt-2">HOME</button>
            </Link>
          </div>
          <div className="specifwidth ">
            <Link to="/Plan">
              <button className="newbtn mt-2 ">Plans</button>
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Stvoffer;
