import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";

i18next.init({
    interpolation: { escapeValue: false },  // React already does escaping
});
const registerServiceWorker = async () => {
  if ('serviceWorker' in navigator) {
      try {
          const registration = await navigator.serviceWorker.register('/firebase-messaging-sw.js');
          console.log('Service Worker registered with scope:', registration.scope);
      } catch (error) {
          console.error('Service Worker registration failed:', error);
      }
  } else {
      console.warn('Service Workers are not supported in this browser.');
  }
};


registerServiceWorker();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <I18nextProvider i18n={i18next}>
        <App />
      </I18nextProvider>
  </React.StrictMode>
);

